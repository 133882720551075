import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Heading from '../../../../components/ui/heading'
import Tabs, { TabHeader, NavItem, TabContent, TabPane } from '../../../../components/ui/tabs/layout-one'
import { SectionWrap } from './style'
import { MdDone } from "react-icons/md";

const TabsSection = () => {
    return (
        <SectionWrap>
            <div fluid className="service-bgcolor service1">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="headstyle" >
                                <Heading as="h4">MODERN WEB APPS - UI</Heading>
                                <span className="commandash"></span>
                                <span className="headtag">Lorem Ipsum is simply dummy</span>
                            </div>
                            <p>Modern web experiences aim to deliver the best possible experience to the widest possible audience, regardless of browser or device. It embraces the variability of the web by prioritizing content delivery to all browsers, and then progressively layer enhancements according to the device or browser capabilities. </p>
                            <p>We build modern web apps with SPA (Single-Page Application) and PWA (Progressive Web Apps).</p>
                        </Col>
                    </Row>
                    <Row className="section-space">
                        <Col lg={6} className="sershape sevice-img">
                            <img src="../../../../spa.png"></img>
                        </Col>
                        <Col lg={6}>
                            <Heading as="h5">Single-Page Application (SPA)</Heading>
                            <p>A single-page application is an app that works inside a browser and does not require page reloading during use. You are using this type of applications every day. These are, for instance: Gmail, Google Maps, Facebook or GitHub. Progressive Web App (PWA) is a term</p>
                            <p>modern browsers with the benefits of mobile experience. SPAs are all about serving an outstanding UX by trying to imitate a “natural” environment in the browser — no page reloads, no extra wait time. It is just one web page that you visit which then loads all</p>
                            <p my="15px">other content using JavaScript — which they heavily depend on.</p>
                        </Col>
                    </Row>
                    <Row className="section-space">
                        <Col lg={6} className="order-lg-1 order-2">
                            <Heading as="h5">Progressive Web Apps (PWA)</Heading>
                            <p>Progressive Web App (PWA) is a term used to denote a new software development methodology. Unlike traditional applications, progressive web apps are a hybrid of regular web pages (or websites) and a mobile application. This new application model attempts to combine features offered by most modern browsers with the benefits of mobile experience.</p>
                            <ul>
                                <li>Reliable: Fast loading and works offline</li>
                                <li>Fast: Smooth animations, jank free scrolling and seamless navigation even on flaky networks.</li>
                                <li>Engaging: Launched from home screen and can receive push notification.</li>
                            </ul>
                        </Col>
                        <Col lg={6} className="sershape-right sershape sevice-img order-lg-2 order-1">
                            <img src="../../../../pwa.png" class></img>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div fluid className="service-bgcover service2">
                <Container>
                    <div className="headstyle">
                        <Heading as="h4" color="white">MODERN WEB APPS – API </Heading>
                        <span className="commandash"></span>
                        <span className="headtag">Lorem Ipsum is simply dummy</span>
                    </div>
                    <p className="text-white">Building business logic as services to help any interface to communicate seamless, scalable, resilient and cohesive using  RestFul architecture and Micro services approach.</p>
                    <Row className="section-space">
                        <Col lg={6} className="sevice-img" >
                            <img src="../../../../reset-api.png"></img>
                        </Col>
                        <Col lg={6} >
                            <Heading as="h5" color="white">Restful API</Heading>
                            <p className="text-white">One of the most popular types of API is REST or, as they're sometimes known, RESTful APIs. REST or RESTful APIs were designed to take advantage of existing protocols. While REST - or Representational State Transfer - can be used over nearly any protocol, when used for web APIs it typically takes advantage of HTTP.</p>
                            <ul className="mb-3 text-white">
                                <li><MdDone className="Tickmark" />Statelessness</li>
                                <li><MdDone className="Tickmark" />Safety & Idempotence</li>
                                <li><MdDone className="Tickmark" />Representations</li>
                                <li><MdDone className="Tickmark" />Caching</li>
                                <li><MdDone className="Tickmark" />Uniform Interface</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="section-space">
                        <Col lg={6} className="order-lg-1 order-2">
                            <Heading as="h5" color="white">Micro Services</Heading>
                            <p className="text-white">Microservice architecture is a method of developing software applications as a suite of independently deployable, small, modular services in which each service runs a unique process and communicates through a well-defined, lightweight mechanism to serve a business goal.</p>
                            <ul className="text-white">
                                <li><MdDone className="Tickmark" />Single Responsibility Principle</li>
                                <li><MdDone className="Tickmark" />Domain Driven Development</li>
                                <li><MdDone className="Tickmark" />Bounded Context</li>
                                <li><MdDone className="Tickmark" />Independently Managed</li>
                                <li><MdDone className="Tickmark" />Typically need/adopt the “DevOps” model</li>
                                <li><MdDone className="Tickmark" />Faster and simpler deployments and rollbacks</li>
                                <li><MdDone className="Tickmark" />Right framework/tool/language for each domain</li>
                                <li><MdDone className="Tickmark" />Greater Resiliency</li>
                                <li><MdDone className="Tickmark" />Better Availability</li>
                            </ul>
                        </Col>
                        <Col lg={6} className="sershape-two sevice-img order-lg-2 order-1">
                            <img className="shapeimg" src="../../../../shape.png"></img>
                            <img src="../../../../micoservices.jpg"></img>
                        </Col>
                    </Row>
                </Container>
            </div>
        </SectionWrap>

    )
}

export default TabsSection
