import styled from 'styled-components';
import { device } from '../../../../theme'

export const SectionWrap = styled.div`
        .service1,.service2{padding-top:60px;padding-bottom:60px;}
        h4{font-size: 60px;line-height: 1;}
        h5{font-size: 52px;line-height: 70px;padding-bottom: 35px;}
        .section-space{padding-top: 30px;padding-bottom: 30px;}
        .sevice-img{margin-bottom:30px;}
    @media ${device.medium}{
        .service1,.service2{padding-top:40px;padding-bottom:40px;}
        h4{font-size: 38px;line-height: 1;}
        h5{font-size: 34px;line-height: 70px;padding-bottom:20px;}
        .section-space{padding-top: 20px;padding-bottom: 20px;}
        .sevice-img{padding-bottom:20px;}
        }
    @media ${device.small}{
        .service1,.service2{padding-top:30px;padding-bottom:30px;}
        h4{font-size: 28px;line-height: 1;}
        h5{font-size: 24px;line-height: 1;padding-bottom:15px;}
        .section-space{padding-top: 10px;padding-bottom: 10px;}
        .sevice-img{padding-bottom:10px;}
    }
    .row{align-items: center!important;}
    .Tickmark{font-weight: 400;
    color: #f36d21;
    font-size: 24px;
    line-height: 1;
    margin-right:10px
    }
    
    .headstyle{display:flex;align-items: center;margin-bottom:15px}
    .headtag{color:#717171;display:none}
    .service-bgcover .headtag{color:#ffffffa8}
    .service-bgcover{
        background:linear-gradient(0deg,rgba(52, 54, 110, 0.88),rgba(39,61,100,0.92)),url(../../../../../servicesection-web1.jpg);
        background-position: center center;
        background-size: cover;}
    .service-bgcolor{background: #e8e8e84a;}
    .commandash{width:35px;height:3px;background:#f36d21;margin: 10px; display:none}
    .sershape{position: relative;z-index: 2;}
    .sershape::before {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-bottom: 350px solid #f36d21;
        border-left: 350px solid transparent;
        right: 12px;
        bottom: -44px;
        z-index: -1;}
        @media only screen and (max-width: 991px) and (min-width: 768px){
       .sershape::before {
          right: -30px;
          bottom: -30px;}
          .sershape img {width: 100%;}
}
.sershape-two{position: relative;z-index: 2;}
.shapeimg{ position: absolute;content: '';right: 0;bottom: -44px;z-index: -1;image-}
@media (max-width: 767px){
    .sershape img{width: 100%;}
    .sershape::before {display: none;}
}

`;  